export const CMS_VER = "0.1.4";

// App Mode
export let MODE = "PRODUCTION";
// export let MODE = "DEVELOPMENT";
// export let MODE = 'LOCAL';

export const MAIN_HOST =
    MODE === "PRODUCTION"
        ? "https://service.notain.id/"
        : MODE === "DEVELOPMENT"
          ? "https://dev-service.notain.id/"
          : "http://localhost:8181/";

export const URL_AUTH = MODE === "PRODUCTION" ? ".notain.id" : "localhost";

export const MAIN_HOST_IMG = `${MAIN_HOST}images/`;

export const API = `${MAIN_HOST}mitra/`;
